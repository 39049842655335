const Contact = () => {
  return (
    <div className='bg-black absolute w-full'>
      <div className='mt-40 '>
        <h1> This is Contact use page </h1>
      </div>
    </div>
  );
};
export default Contact;
